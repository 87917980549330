import React from 'react';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import { Link } from '@material-ui/core';

const FieldAlertMessage = ({ schema }) => {
  const { title, description } = schema;

  const checkLink = (element) => {
    if (element.includes('<a href=') && element.includes('</a>')) {
      // const regex = /(?=<a href=.*>.*<\/a>)| (?<=<a href=\.\*>\.\*<\/a>)/g;
      const regex2 = /([^<>]*?)(<\/?[-:\w]+(?:>|\s[^<>]*?>))/g;
      let splitLinks = element.split(regex2);
      splitLinks = splitLinks.filter(Boolean);

      const ret = [];
      let href = '';
      let tagContent = '';
      let previousOpenningTag = false;
      splitLinks.map((found) => {
        if (found.includes('<a href=')) {
          // eslint-disable-next-line prefer-destructuring
          href = found.split('"')[1];

          previousOpenningTag = true;
          return null;
        }
        if (previousOpenningTag) {
          previousOpenningTag = false;
          tagContent = found;
          return null;
        }
        if (found.includes('</a>')) {
          ret.push(
            <Link href={href} target='_blank'>
              {tagContent}
            </Link>
          );
          return null;
        }
        ret.push(found);
        return null;
      });
      return ret;
    }
    return element;
  };

  return (
    <Alert severity={title} variant='filled'>
      {description
        .split('***')
        .map((element, index) =>
          index % 2 ? (
            <strong key={index}>{checkLink(element)}</strong>
          ) : (
            checkLink(element)
          )
        )}
    </Alert>
  );
};

FieldAlertMessage.propTypes = {
  schema: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }),
};

export default FieldAlertMessage;
